export default class ErrorService {
  constructor() {}

  static onError(error) {
    const response = error.response
    if (response && response.status >= 400 && response.status < 405) {
      ErrorService.sentryLogEngine(error)
      return false
    }
    ErrorService.logRocketLogEngine(error)
  }

  static onWarn(error) {
    this.logRocketLogEngine(error)
  }

  static onInfo(error) {
    this.sentryLogEngine(error)
  }

  static onDebug(error) {
    const response = error.response
    if (response && response.status >= 400 && response.status < 405) {
      this.sentryLogEngine(error)
      return false
    }
    this.logRocketLogEngine(error)
  }

  static initHandler() {
    const scope = this
    window.onerror = (message, url, lineNo, columnNo, error) => {
      if (error) {
        scope.onError(error)
        // console.log(message, url, lineNo, columnNo, error)
      }
    }
  }

  static displayErrorAlert() {
    // console.log(message)
  }

  static logRocketLogEngine() {
    // console.log(error, 'LogRocket')
  }

  static sentryLogEngine() {
    // console.log(error, 'Sentry')
  }
}
