import oAuth from './oAuth/oAuth'

class Sharesight {
  constructor() {
    this.oAuth = new oAuth()
  }

  get oauthLoginURL() {
    return this.oAuth.loginUrl
  }

  get oauthLogoutURL() {
    return this.oAuth.logoutUrl
  }
}

export default new Sharesight()
