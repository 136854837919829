class OAuth {
  constructor() {
    this.oauthURI = process.env.VUE_APP_API_URI
  }

  get loginUrl() {
    return `${this.oauthURI}/oauth/login`
  }

  get logoutUrl() {
    return `${this.oauthURI}/oauth/logout`
  }
}

export default OAuth
