import { createRouter, createWebHashHistory } from 'vue-router'
import { default as PageNotFound } from '../components/PageNotFound'
import Home from '@/components/Home'
import About from '@/components/About'
import Portfolios from '@/components/Portfolios'
import PerformanceReport from '@/components/PerformanceReport'
import LoggedOut from '@/components/LoggedOut'
import PreviewMembershipPortfolios from '@/components/PreviewMembershipPortfolios'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', component: Home },
    { path: '/about', component: About },
    {
      path: '/portfolios',
      component: Portfolios,
      meta: { authRequired: true }
    },
    {
      path: '/performance-report/:portfolioId',
      name: 'performance-report',
      component: PerformanceReport,
      props: true,
      meta: { authRequired: true }
    },
    {
      path: '/preview-membership-portfolios',
      name: 'preview-membership-portfolios',
      props: true,
      component: PreviewMembershipPortfolios,
      meta: { authRequired: true }
    },
    { path: '/performance-report-no-auth', component: PerformanceReport }, // TODO remove once auth precondition implemented
    { path: '/:pathMatch(.*)*', component: PageNotFound },
    { path: '/logged-out', component: LoggedOut }
  ]
})

export default router
