<template>
  <div class="border-b-2 border-gray-100 p-6">
    <div class="container flex justify-between items-center mx-auto">
      <button @click="handleLogoClicked">
        <span class="sr-only">Investasight</span
        ><img
          class="h-8 w-auto sm:h-10"
          src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
          alt=""
        />
      </button>
      <button class="non-print-item" @click="handleAboutClicked">About</button>
      <button
        v-if="isAuthenticated"
        class="non-print-item"
        @click="handlePortfoliosClicked"
      >
        Portfolios
      </button>
      <form v-if="isAuthenticated" :action="oauthLogoutURL" method="post">
        <input class="non-print-item" type="submit" value="Logout" />
      </form>
      <form v-else :action="oauthLoginURL" method="post">
        <input class="non-print-item" type="submit" value="Login" />
      </form>
    </div>
  </div>
</template>

<script>
import Sharesight from '@/lib/sharesight/sharesight'

export default {
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    oauthLoginURL: () => {
      return Sharesight.oauthLoginURL
    },
    oauthLogoutURL: () => {
      return Sharesight.oauthLogoutURL
    }
  },
  methods: {
    handleAboutClicked() {
      this.$router.push('/about')
    },
    handlePortfoliosClicked() {
      this.$router.push('/portfolios')
    },
    handleLogoClicked() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
@media print {
  .non-print-item {
    visibility: hidden;
  }
}
</style>
