<template>
  <div class="table-chart">
    <table class="table-auto bg-green-400 table-chart">
      <thead class="bg-green-800 text-white">
        <tr>
          <th class="p-4">Holding</th>
          <th class="p-4">Market</th>
          <th class="p-4">Value</th>
          <th class="p-4">12 Month Total Percentage Gain (%)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="performance in performances" :key="performance.id">
          <td class="p-4">{{ performance.name }}</td>
          <td class="p-4">{{ performance.market_code }}</td>
          <td class="p-4">
            {{ performance.value }}
          </td>
          <td class="text-center font-bold p-4" :class="performance.cssClass">
            {{ performance.toS }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
class PerformancePresenter {
  constructor(props) {
    const { instrument, value, total_gain_percent } = props
    this.name = instrument.name
    this.market_code = instrument.market_code
    this.gain = total_gain_percent
    this.value = `$${value}`
  }

  get isGain() {
    return this.gain > 0
  }

  get toS() {
    return `${this.gain}%`
  }

  get cssClass() {
    return this.isGain ? 'gain' : 'loss'
  }
}

export default {
  name: 'ReportTable',
  props: {
    reportData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    performances() {
      return this.reportData.holdings.map((obj) => new PerformancePresenter(obj))
    }
  }
}
</script>

<style scoped>
table.table-auto {
  text-align: left;
}
tbody tr:nth-child(even) {
  @apply bg-green-200;
}
td.gain {
  @apply text-green-800;
}
td.loss {
  @apply text-red-800;
}
@media print {
  .table-chart {
    margin-left: 20px;
  }
}
</style>
