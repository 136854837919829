import Cookie from '../cookie/cookie'

export default {
  sessionCookieName: process.env.VUE_APP_SESSION_COOKIE,
  get isAuthenticated() {
    return Cookie.hasKey(this.sessionCookieName)
  },
  logout() {
    Cookie.delete(this.sessionCookieName)
  }
}
