<template>
  <div>
    <h1>Portfolios</h1>
    <Loader :loading="portfoliosLoading" :text="'Loading Portfolios'">
      <div v-for="portfolio in portfolios" :key="portfolio.id">
        <Portfolio :portfolio="portfolio" />
      </div>
      <button @click="handlePreviewPortfoliosClicked">
        Preview Membership Portfolios
      </button>
    </Loader>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import Portfolio from '@/components/Portfolio'

export default {
  name: 'Portfolios',

  components: { Portfolio, Loader },

  computed: {
    portfolios() {
      return this.$store.state.portfolios.portfolios
    },
    portfoliosLoading() {
      return this.$store.state.portfolios.portfoliosLoading
    }
  },

  mounted() {
    this.$store.dispatch('fetchPortfolios')
  },

  methods: {
    handlePreviewPortfoliosClicked() {
      this.$router.push('preview-membership-portfolios')
    }
  }
}
</script>

<style>
.animate-spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
