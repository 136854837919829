<template>
  <div class="bottom-graph">
    <pie-chart
      width="100%"
      height="100%"
      :data="performanceData"
      loading="Loading..."
      :download="true"
      :colors="['teal', 'turquoise']"
      suffix="%"
    ></pie-chart>
  </div>
</template>

<script>
export default {
  name: 'Graph',
  props: {
    reportData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    performanceData() {
      return this.reportData.holdings.map((holding) => {
        const percentage = Math.round((holding.value / this.reportData.value) * 100)
        return [holding.instrument.name, percentage]
      })
    }
  }
}
</script>

<style scoped>
.bottom-graph {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
</style>
