<template>
  <div>
    <h1>Your session has timed out</h1>
    <p>Hit Login to restart it</p>
  </div>
</template>

<script>
export default { name: 'LoggedOut' }
</script>
