import './stylesheets/app.css'
import { createApp } from 'vue'
import Routes from './config/routes'
import App from './App.vue'
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'
import store from './store'
import ErrorService from './Services/ErrorService'

const app = createApp(App)
app.use(store)
app.use(Routes)
app.mount('#app')
app.use(VueChartkick)
app.config.errorHandler = (error) => ErrorService.onError(error)
