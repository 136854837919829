<template>
  <div>
    <div v-for="portfolio in portfolios" :key="portfolio.id">
      <PerformanceReport
        :portfolio-id="portfolio.id.toString()"
        :owner-name="portfolio.owner_name"
        class="bottom-border"
      />
    </div>
  </div>
</template>

<script>
import PerformanceReport from '@/components/PerformanceReport'
export default {
  name: 'PreviewMembershipPortfolios',
  components: {
    PerformanceReport
  },
  computed: {
    portfolios() {
      return this.$store.state.portfolios.portfolios
    }
  },
  mounted() {
    if (!this.portfolios) {
      this.$store.dispatch('fetchPortfolios')
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-border {
  padding-bottom: $padding;
  border-bottom: 1px solid #ddd;
}
</style>
