<template>
  <div>
    <Header />
    <Loader :loading="userLoading" :text="'Loading User'">
      <div class="container mx-auto">
        <router-view />
      </div>
    </Loader>
  </div>
</template>

<script>
import Header from '@/components/Header'
import { useRouter } from 'vue-router'

import Loader from './components/Loader'

export default {
  name: 'App',
  components: { Header, Loader },

  computed: {
    userLoading() {
      return this.$store.state.user.userLoading
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    username() {
      return this.$store.state.user.username
    },
    errors() {
      return this.$store.state.user.errors
    }
  },

  mounted() {
    useRouter().beforeEach(async (to, from, next) => {
      if (this.username) {
        this.authenticate(to, next)
      } else {
        await this.$store
          .dispatch('getUsername')
          .then(() => {
            this.authenticate(to, next)
          })
          .catch((error) => {
            this.$store.dispatch('storeError', error)
            this.$store.dispatch('logout')
            this.$router.push('/logged-out')
            throw new Error(error)
          })
      }
    })
  },

  methods: {
    authenticate(to, next) {
      if (to.matched.some((record) => record.meta.authRequired)) {
        if (!this.isAuthenticated) {
          next('/logged-out')
        } else {
          next()
        }
      } else next()
    }
  }
}
</script>

<style>
#app {
}
</style>
