import Authentication from '@/lib/authentication/authentication'
import router from '../../config/routes'

export default {
  state: () => ({
    username: null,
    userLoading: false,
    errors: [],
    loggedIn: false
  }),
  mutations: {
    SET_USERNAME(state, username) {
      state.username = username
    },
    SET_USER_LOADING(state, bool) {
      state.userLoading = bool
    },
    SET_LOGGED_IN(state) {
      state.loggedIn = !state.loggedIn
    },
    STORE_ERRORS: (state, error) => {
      // Call Error Service here
      // ErrorService.onError(error)
      // ErrorService.initHandler()

      // Store error to state(optional)
      if (error.message) {
        state.errors = [...state.errors, error.message]
      }
    }
  },
  actions: {
    getUsername({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_USER_LOADING', true)
        fetch(`${process.env.VUE_APP_API_URI}/sharesight/my_user`, {
          credentials: 'include'
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((data) => {
                commit('SET_USERNAME', data.user.name)
                resolve(response)
              })
            } else {
              commit('SET_USERNAME', null)
              commit('STORE_ERRORS', response.status)
              resolve(response)
            }
            commit('SET_USER_LOADING', false)
          })
          .catch((error) => {
            commit('STORE_ERRORS', error)
            reject(error.message)
          })
      })
    },
    storeError({ commit }, error) {
      commit('STORE_ERRORS', error)
    },

    logout({ commit }) {
      router.push('/logged-out')
      Authentication.logout()
      commit('SET_USERNAME', null)
      commit('SET_LOGGED_IN')
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.username
  }
}
