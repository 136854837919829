const Cookie = {
  hasKey(key, cookie = document.cookie) {
    let username = this.get(key, cookie)

    return username != '' && username != null
  },

  get(cname, cookie = document.cookie) {
    let result = cookie.split(';').find((row) => row.trim().startsWith(`${cname}=`))

    return result ? result.split('=')[1] : null
  },

  set(cname, cvalue, document = window.document) {
    document.cookie = `${cname}=${cvalue}`
  },

  delete(cname) {
    this.set(cname, '')
  }
}

export default Cookie
