<template>
  <div class="bottom-graph">
    <column-chart
      width="100%"
      height="100%"
      loading="Loading..."
      :data="performanceData"
      prefix="$"
      :download="true"
      :colors="['hotpink', 'blue']"
    ></column-chart>
  </div>
</template>

<script>
export default {
  name: 'Graph',
  props: {
    reportData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    performanceData() {
      const extractData = ({ capital_gain, payout_gain, currency_gain, total_gain }) => {
        return {
          capital_gain,
          payout_gain,
          currency_gain,
          total_gain
        }
      }
      //This graph is hardcoded for 3 reports//
      return [
        { name: 'Last 12 Months', data: extractData(this.reportData[0]) },
        { name: 'Last 6 Months', data: extractData(this.reportData[1]) },
        { name: 'Last 3 Months', data: extractData(this.reportData[2]) }
      ]
    }
  }
}
</script>

<style scoped>
.bottom-graph {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
</style>
