<template>
  <line-chart
    width="100%"
    height="100%"
    :data="performanceData"
    prefix="$"
    loading="Loading..."
    :download="true"
  ></line-chart>
</template>

<script>
export default {
  name: 'Graph',
  props: {
    reportData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    holdings() {
      return this.$store.state.portfolios.portfolioPerformanceReports[
        this.reportData.portfolio_id
      ][this.reportData.start_date].holdings
    },
    performanceData() {
      const graphData = []
      const dates = this.reportData.dates
      this.reportData.lines.map((report) => {
        //use this if we want to individually label holdings in graph
        // const names = []

        // this.holdings.filter((holding) => {
        //   if (
        //     report.label.includes(
        //       this.$store.getters
        //         .portfolioById(this.reportData.portfolio_id)
        //         .owner_name.split(' ')[0]
        //     )
        //   ) {
        //     return names.push(report.label)
        //   } else if (holding.instrument.market_code === report.label) {
        //     return names.push(holding.instrument.name)
        //   }
        // })
        // var uniq = [...new Set(names)]
        const data = {}
        const values = report.values
        dates.map((date, i) => (data[date] = values[i]))
        return graphData.push({
          name: `${report.label}`,
          data
        })
      })
      return graphData
    }
  }
}
</script>
