<template>
  <Loader :loading="isLoading" :text="'Loading Reports'">
    <h1 class="my-4">Performance Report</h1>
    <p class="mb-4">Dear {{ ownerName }}</p>
    <p class="mb-4">
      This is your quarterly update. Your portfolio value has moved by
      {{ portfolioPerformanceReport[threeMonthDate].total_gain_percent }}% over the last
      three months.
    </p>
    <div class="charts-container">
      <div class="charts-content">
        <table class="table-auto bg-green-400 table-chart">
          <thead class="bg-green-800 text-white">
            <tr>
              <th class="p-4">Period</th>
              <th class="p-4">Total Percentage Gain (%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="performance in performances" :key="performance.period">
              <td class="p-4">{{ performance.humanPeriod }}</td>
              <td class="text-center font-bold p-4" :class="performance.cssClass">
                {{ performance.toS }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="graph-container">
        <p>Hypothetical Growth of $10000</p>
        <LineGraph class="graph" :report-data="portfolioChartData" />
      </div>
      <div class="graph-container">
        <p>Gains Comparison</p>
        <BarGraph
          class="graph"
          :report-data="[
            portfolioPerformanceReport[twelveMonthDate],
            portfolioPerformanceReport[sixMonthDate],
            portfolioPerformanceReport[threeMonthDate]
          ]"
        />
      </div>
      <div class="charts-content">
        <ReportTable
          class="table-chart"
          :report-data="portfolioPerformanceReport[twelveMonthDate]"
        />
      </div>
      <div class="graph-container">
        <p>Current Holdings</p>
        <PieGraph
          class="graph"
          :report-data="portfolioPerformanceReport[twelveMonthDate]"
        />
      </div>
    </div>
    <br />
    <div class="non-print-item">
      <a :href="inlinePdfUrl" target="_blank">Print to Browser</a> |
      <a :href="filePdfUrl">Print to File</a> |
      <a :href="emailPdfUrl">Email Report</a>
      <form :action="bulkEmailUrl" method="post">
        <input type="hidden" name="report" value="performance-report-no-aut" />
        <input type="hidden" name="memberships" value="true" />
        <input type="submit" value="Bulk Email Report" />
      </form>
    </div>
  </Loader>
</template>

<script>
class PerformancePresenter {
  constructor(props) {
    const { period, totalPercentageGain } = props
    this.period = period
    this.gain = totalPercentageGain
  }

  get isGain() {
    return this.gain > 0
  }

  get toS() {
    return `${this.gain}%`
  }

  get cssClass() {
    return this.isGain ? 'gain' : 'loss'
  }

  get humanPeriod() {
    const periodNumber = this.period.slice(0, -1)
    return `last ${periodNumber} months`
  }
}

import BarGraph from '@/components/Graphs/Bar'
import LineGraph from '@/components/Graphs/Line'
import PieGraph from '@/components/Graphs/Pie'
import Loader from '@/components/Loader'
import ReportTable from '@/components/ReportTable'

import { dateConverter } from '@/lib/utils'

const todayDate = dateConverter(0)

const threeMonthDate = dateConverter(3)

const sixMonthDate = dateConverter(6)

const twelveMonthDate = dateConverter(12)

export default {
  components: {
    BarGraph,
    LineGraph,
    PieGraph,
    Loader,
    ReportTable
  },
  props: {
    portfolioId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      threeMonthDate,
      sixMonthDate,
      twelveMonthDate
    }
  },

  computed: {
    username() {
      return this.$store.state.user.username
    },
    ownerName() {
      return this.$store.getters.portfolioById(this.portfolioId || this.portfolioIdByUrl)
        .owner_name
    },
    portfolioIdFromUrl() {
      const route = location.hash.split('/')
      const id = route[route.length - 1]
      return id
    },
    isLoading() {
      return (
        this.$store.state.portfolios.portfolioPerformanceReportsLoading ||
        this.$store.state.portfolios.portfolioChartDataLoading
      )
    },
    performances() {
      return this.tableData.map((obj) => new PerformancePresenter(obj))
    },
    portfolioPerformanceReport() {
      return this.$store.state.portfolios.portfolioPerformanceReports[this.portfolioId]
    },
    portfolioChartData() {
      return this.$store.state.portfolios.portfolioChartData[this.portfolioId]
    },
    tableData() {
      return [
        {
          period: '3m',
          totalPercentageGain:
            this.portfolioPerformanceReport[threeMonthDate].total_gain_percent
        },
        {
          period: '6m',
          totalPercentageGain:
            this.portfolioPerformanceReport[sixMonthDate].total_gain_percent
        },
        {
          period: '12m',
          totalPercentageGain:
            this.portfolioPerformanceReport[twelveMonthDate].total_gain_percent
        }
      ]
    },
    inlinePdfUrl() {
      return `${process.env.VUE_APP_API_URI}/pdf/generate?url=${encodeURIComponent(
        `${process.env.VUE_APP_CLIENT_URI}/#/performance-report/${this.portfolioId}`
      )}&output=inline`
    },
    filePdfUrl() {
      return `${process.env.VUE_APP_API_URI}/pdf/generate?url=${encodeURIComponent(
        `${process.env.VUE_APP_CLIENT_URI}/#/performance-report/${this.portfolioId}`
      )}&output=file&filename=investasight_performance_report.pdf`
    },
    emailPdfUrl() {
      return `${process.env.VUE_APP_API_URI}/pdf/generate?url=${encodeURIComponent(
        `${process.env.VUE_APP_CLIENT_URI}/#/performance-report/${this.portfolioId}`
      )}&output=email&filename=investasight_performance_report.pdf`
    },
    bulkEmailUrl() {
      return `${process.env.VUE_APP_API_URI}/bulk_email`
    }
  },
  async mounted() {
    try {
      if (!this.portfolioPerformanceReport) {
        this.$store.dispatch('fetchPortfolioPerformanceReports', {
          portfolioID: this.portfolioId,
          startDates: [twelveMonthDate, sixMonthDate, threeMonthDate],
          endDate: todayDate
        })
      }
      if (!this.ownerName) {
        this.$store.dispatch('fetchPortfolios')
      }
      if (!this.portfolioChartData) {
        this.$store.dispatch('fetchPortfolioChartData', {
          portfolioID: this.portfolioId,
          startDate: twelveMonthDate,
          endDate: todayDate
        })
      }
    } catch (error) {
      this.$store.dispatch('logout')
      this.$router.push('/logged-out')
      throw new Error(error)
    }
  }
}
</script>

<style scoped>
table.table-auto {
  text-align: left;
}
tbody tr:nth-child(2) {
  @apply bg-green-200;
}
td.gain {
  @apply text-green-800;
}
td.loss {
  @apply text-red-800;
}
.charts-container {
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.charts-content {
  width: 50%;
  display: flex;
  justify-content: center;
}
.table-chart {
  font-size: 1.3vw;
  height: 20vw;
  aspect-ratio: 2/1;
  margin-top: 15%;
}
.graph-container {
  width: 50%;
  aspect-ratio: 2/1;
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.graph {
  margin-top: 0;
  height: 100%;
  width: 100%;
}
@media print {
  @page {
    size: A4 portrait;
    margin: 0 5%;
  }
  .non-print-item {
    visibility: hidden;
  }
  .graph-container {
    margin-top: 2%;
  }
  .graph {
    transform: scale(0.35);

    margin-left: -60%;
    margin-top: -17%;
  }
  .table-chart {
    transform: scale(0.9);
  }
}
</style>
