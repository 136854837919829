<template>
  <div>
    <p>uri address = {{ uri }}</p>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  computed: {
    uri: function () {
      return process.env
    }
  }
}
</script>
