import { createStore } from 'vuex'

import user from './modules/user'
import portfolios from './modules/portfolios'

export default createStore({
  modules: {
    user,
    portfolios
  }
})
