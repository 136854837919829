<template>
  <div>
    <button @click="handlePortfolioClicked">
      {{ portfolio.id }}: {{ portfolio.name }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'Portfolio',
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },

  methods: {
    handlePortfolioClicked() {
      this.$router.push({
        name: 'performance-report',
        params: { portfolioId: this.portfolio.id }
      })
    }
  }
}
</script>
