export default {
  state: () => ({
    portfolios: [],
    portfoliosLoading: false,
    portfolioPerformanceReports: {},
    portfolioPerformanceReportsLoading: false,
    portfolioChartData: {},
    portfolioChartDataLoading: false
  }),

  actions: {
    async fetchPortfolios({ commit, dispatch }) {
      commit('SET_PORTFOLIOS_LOADING', true)

      const response = await fetch(
        `${process.env.VUE_APP_API_URI}/sharesight/portfolios`,
        {
          credentials: 'include'
        }
      )

      if (response.status >= 400) {
        commit('SET_PORTFOLIOS_LOADING', false)
        dispatch('logout')
        throw response.status
      }
      const data = await response.json()
      data.portfolios && commit('SET_PORTFOLIOS', data.portfolios)
      commit('SET_PORTFOLIOS_LOADING', false)
    },

    async fetchPortfolioPerformanceReports(
      { commit, dispatch },
      { portfolioID, startDates, endDate }
    ) {
      commit('SET_PORTFOLIO_PERFORMANCE_REPORTS_LOADING', true)
      await startDates.reduce(async (fetchReport, startDate) => {
        await fetchReport

        await dispatch('fetchPortfolioPerformanceReport', {
          portfolioID,
          startDate,
          endDate
        })
      }, Promise.resolve())
      commit('SET_PORTFOLIO_PERFORMANCE_REPORTS_LOADING', false)
    },

    async fetchPortfolioPerformanceReport(
      { commit, dispatch },
      { portfolioID, startDate, endDate }
    ) {
      const queryStringParams = `portfolioID=${portfolioID}&startDate=${startDate}&endDate=${endDate}`
      const response = await fetch(
        `${process.env.VUE_APP_API_URI}/sharesight/portfolioPerformanceReport?${queryStringParams}`,
        {
          credentials: 'include'
        }
      )

      if (response.status >= 400) {
        dispatch('logout')
        throw response.status
      }
      const data = await response.json()
      data.report && commit('SET_PORTFOLIO_PERFORMANCE_REPORTS', data.report)
    },

    async fetchPortfolioChartData(
      { commit, dispatch },
      { portfolioID, startDate, endDate }
    ) {
      commit('SET_PORTFOLIO_CHART_DATA_LOADING', true)
      const queryStringParams = `portfolioID=${portfolioID}&startDate=${startDate}&endDate=${endDate}`

      const response = await fetch(
        `${process.env.VUE_APP_API_URI}/sharesight/portfolioChartData?${queryStringParams}`,
        {
          credentials: 'include'
        }
      )

      if (response.status >= 400) {
        commit('SET_PORTFOLIO_CHART_DATA_LOADING', false)
        dispatch('logout')
        throw response.status
      }
      const data = await response.json()
      data && commit('SET_PORTFOLIO_CHART_DATA', data)
      commit('SET_PORTFOLIO_CHART_DATA_LOADING', false)
    }
  },

  getters: {
    portfolioById: (state) => (id) => {
      return state.portfolios.find((portfolio) => portfolio.id === parseInt(id))
    }
  },

  mutations: {
    SET_PORTFOLIOS(state, portfolios) {
      state.portfolios = portfolios
    },

    SET_PORTFOLIOS_LOADING(state, loadingState) {
      state.portfoliosLoading = loadingState
    },

    SET_PORTFOLIO_PERFORMANCE_REPORTS(state, portfolioPerformanceReport) {
      const { portfolio_id: portfolioID, start_date: startDate } =
        portfolioPerformanceReport

      state.portfolioPerformanceReports = {
        ...state.portfolioPerformanceReports,
        [portfolioID]: {
          ...state.portfolioPerformanceReports[portfolioID],
          [startDate]: portfolioPerformanceReport
        }
      }
    },

    SET_PORTFOLIO_PERFORMANCE_REPORTS_LOADING(state, loadingState) {
      state.portfolioPerformanceReportsLoading = loadingState
    },

    SET_PORTFOLIO_CHART_DATA(state, portfolioChartData) {
      var portfolioID = portfolioChartData.portfolio_id

      state.portfolioChartData = {
        ...state.portfolioChartData,
        [portfolioID]: portfolioChartData
      }
    },

    SET_PORTFOLIO_CHART_DATA_LOADING(state, loadingState) {
      state.portfolioChartDataLoading = loadingState
    }
  }
}
